import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home/Home.vue'
import OpenCall from './views/OpenCall/OpenCall.vue'
import Contact from './views/Contact/Contact.vue'
import recoverPassword from '@/components/openCall/recoverPassword/recoverChangePassword.vue';

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // {
    //   path: '/',
    //   name: 'home',
    //   component: Home
    // },
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: function () { 
    //     return import(/* webpackChunkName: "about" */ './views/About/About.vue')
    //   }
    // },
    // {
    //   path: '/recoverPassword',
    //   name: 'recoverChangePassword',
    //   component: function () { 
    //     return import('./components/openCall/recoverPassword/recoverChangePassword.vue')
    //   }
    // },
    {
      path: '/recoverPassword',
      name: 'recoverChangePassword',
      component: recoverPassword
    },
    {
      path: '/',
      name: 'opencall',
      component: OpenCall
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact
    },
  ]
})
