import closeIcon from '@/components/icons/IconClose.vue';
// import logoutIcon from '@/components/icons/IconLogout.vue';
// import leftArrowIcon from '@/components/icons/IconLeftArrow.vue';
// import { EventBus } from '@/assets/static/event-bus.js';

export default{
	mounted(){
		// this.id = this.$parent.itemClicked;
		setTimeout(() => this.modalActive = true, 300);
	},

	data(){
		return{
			id: null,
			modalActive: false,
			// deleteConfirmed: false,
			responseReady: false,
			success: '',
			email : ""
		}
	},

	// props: ['section'],
	
	components:{
		closeIcon,
		// leftArrowIcon,
		// logoutIcon
	},

	methods:{
		closeModal(){
			// this.modalActive = false;
			this.$parent.modalRecoverPasswordOpen = false;
		},
		recoverPassword(scope){

			this.$validator.validateAll(scope).then((result) => {

				if (result) {
					(async () => {
						var rawResponse = await fetch(this.$root.baseUrl_api + 'email', {
							method: 'POST',
							headers: {
								'Accept': 'application/json',
								'Content-Type': 'application/json'
							},
							body: JSON.stringify({
								email: this.email,
							})
						});
						const content = await rawResponse.json();
						if(content.status){
							this.$parent.modalRecoverPasswordOpen = false;
						}
						else
							this.$parent.notify(content.msg);
					})();
				}

			});

		}
	}
}