
export default {
  name: 'contact',
  data(){
    return{
    }
  },
  components:{
  },
  methods:{
  }
}