import closeIcon from '@/components/icons/IconClose.vue';
// import logoutIcon from '@/components/icons/IconLogout.vue';
// import leftArrowIcon from '@/components/icons/IconLeftArrow.vue';
// import { EventBus } from '@/assets/static/event-bus.js';

export default{
	mounted(){
		// this.id = this.$parent.itemClicked;
		setTimeout(() => this.modalActive = true, 300);
	},

	data(){
		return{
			id: null,
			modalActive: true,
			// deleteConfirmed: false,
			responseReady: false,
			success: '',
		}
	},

	// props: ['section'],
	
	components:{
		closeIcon,
		// leftArrowIcon,
		// logoutIcon
	},

	methods:{
		closeModal(){
			// this.modalActive = false;
			// this.$parent.modalSendConvocatoria = false;
			this.$parent.closeModalPriceConfirmation();
		},
	}
}