<template lang="html">
  <svg xmlns="http://www.w3.org/2000/svg" width="9.5" height="5.811" viewBox="0 0 9.5 5.811">
    <path id="Path_300" data-name="Path 300" class="cls-1" d="M222.371,190.871l4-4-4-4" transform="translate(191.621 -221.621) rotate(90)"/>
  </svg>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: none;
  stroke: black;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 1.5px;
}
</style>
