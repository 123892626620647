
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
//import '@/assets/scss/main.scss';

export default {
  name: 'home',
  components: {
    HelloWorld
  }
}