import closeIcon from '@/components/icons/IconClose.vue';

export default{
	mounted(){
		setTimeout(() => this.modalActive = true, 300);
	},

	data(){
		return{
			id: null,
			modalActive: true,
			responseReady: false,
			success: '',
		}
	},

	components:{
		closeIcon,
	},

	methods:{
		closeModal(logout = false){
			this.$parent.closeModalConditionsConfirmation(logout);
		},
	}
}