import closeIcon from '@/components/icons/IconClose.vue';
// import { async } from 'q';
// import logoutIcon from '@/components/icons/IconLogout.vue';
// import leftArrowIcon from '@/components/icons/IconLeftArrow.vue';
// import { EventBus } from '@/assets/static/event-bus.js';

export default{
	mounted(){
		// this.id = this.$parent.itemClicked;
		setTimeout(() => this.modalActive = true, 300);
	},

	data(){
		return{
			id: null,
			modalActive: false,
			responseReady: false,
			success: '',
			changePasswordSuccess: false,

			///////////////
			//// CHANGE PASSWORD
			dChangePassword:{
				password: "",
				newPassword: "",
				newPasswordConfirm: "",
				email: ""
			}  
		}
	},

	// props: ['section'],
	
	components:{
		closeIcon,
		// leftArrowIcon,
		// logoutIcon
	},

	methods:{
		closeModal(){

			this.$parent.modalChangePassword = false;
			this.changePasswordSuccess = false;

		},
		changePassword(scope){

			this.$validator.validateAll(scope).then((result) => {
				if (result) {
					(async () => {
						this.dChangePassword.email = this.$session.get('email');
						// var rawResponse = await fetch('https://sandbox.api.salonacme.com/opencall/change_password', {
						var rawResponse = await fetch(this.$root.baseUrl_api + 'change_password', {
							method: 'PUT',
							headers: {
								'Accept': 'application/json',
								'Content-Type': 'application/json'
							},
							body: JSON.stringify({
								_id: this.$session.get('_id'),
								data: this.dChangePassword
							})
						});
						const content = await rawResponse.json();
						if(content.status){
							this.changePasswordSuccess = true;
							this.dChangePassword.password = "";
							this.dChangePassword.newPassword = "";
							this.dChangePassword.newPasswordConfirm = "";
						}
						else
							console.log(content.msg);
					})();
				}
			});

		}
	}
}