import axios from 'axios'

export default {
  name: 'opencall',
  data() {
    return {
      intro: "<p>Ingresa a la plataforma de aplicación de Salón Acme. Las fechas de cierre de convocatoria podrás verlas en <a href=\"http://localhost/salonacme-website/&lt;a href=&quot;http://salonacme.com&quot;&gt;http://salonacme.com&lt;/a&gt;\">salonacme.com</a></p>",
      logo: "",
      success_apply: {},
      conditions: ""
    }
  },
  components: {
  },
  beforeCreate: function () {
  },
  methods: {
    getOpencall: async function(language = null) {
      try {
        await axios
          // .get("http://localhost/salonacme-website/api/opencall.json")
          // .get("https://clientes.welovecorner.com/salon-acme/api/opencall.json")
          .get("https://salonacme.com/api/opencall.json")
          .then((res) => {
            if(res != undefined && res.status === 200) {
              if(language == 'en') {
                this.logo = res.data[1].logo;
                this.intro = res.data[1].intro;
                this.success_apply.title = res.data[1].success_apply_title;
                this.success_apply.text = res.data[1].success_apply_text;
                this.success_apply.footer = res.data[1].success_apply_footer;
                this.conditions = res.data[1].conditions;
              } else {
                this.logo = res.data[0].logo;
                this.intro = res.data[0].intro;
                this.success_apply.title = res.data[0].success_apply_title;
                this.success_apply.text = res.data[0].success_apply_text;
                this.success_apply.footer = res.data[0].success_apply_footer;
                this.conditions = res.data[0].conditions;
              }
            }
        })
        .catch();
      } catch (error) {}

      this.$forceUpdate();
    }
  },
  watch: {
    '$root.language_option': function(e) {
      this.getOpencall(this.$root.language_option);
    }
  },
  mounted() {
    // this.getOpencall();
  }
}
