import closeIcon from '@/components/icons/IconClose.vue';

export default{
	mounted(){
		// this.id = this.$parent.itemClicked;
		setTimeout(() => this.modalActive = true, 300);
	},

	data(){
		return{
			id: null,
			modalActive: true,
			// deleteConfirmed: false,
			responseReady: false,
			success: '',
			email : "",
			dChangePassword: {
				passsword: "",
				passwordConfirm: ""
			},
			result: "",
			status: false
		}
	},

	// props: ['section'],
	
	components:{
		closeIcon,
		// leftArrowIcon,
		// logoutIcon
	},

	methods:{
		closeModal(){
			// this.modalActive = false;
			// this.$parent.modalRecoverPasswordOpen = false;
		},
		changePassword(scope){

			this.$validator.validateAll(scope).then((result) => {

				if (result && typeof this.$route.query.token !== 'undefined') {
					(async () => {
						// var rawResponse = await fetch('https://sandbox.api.salonacme.com/opencall/verify_recover_password', {
						var rawResponse = await fetch(this.$root.baseUrl_api + 'verify_recover_password', {
							method: 'POST',
							headers: {
								'Accept': 'application/json',
								'Content-Type': 'application/json'
							},
							body: JSON.stringify({
								token: this.$route.query.token,
								password: this.dChangePassword.password,
								passwordConfirm: this.dChangePassword.passwordConfirm
							})
						});
						const content = await rawResponse.json();
						this.status = content.status;
						if(content.status){
							this.result = content.msg;
							setTimeout(()=>{ this.$router.push('/') }, 3000);
						}
						else{
							this.result = content.msg;
						}
					})();
				}

			});

		}
	},
	mounted(){
	}
}