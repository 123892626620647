import recoverPassword from '@/components/openCall/recoverPassword/recoverPassword.vue';
import changePassword from '@/components/openCall/changePassword/changePassword.vue';
import sendConvocatoria from '@/components/openCall/sendConvocatoria/sendConvocatoria.vue';
import IconDownArrowSelect from '@/components/icons/IconDownArrowSelect.vue';
import origamiBird from '@/components/icons/IconOrigamiBird.vue';
import editIcon from '@/components/icons/IconEdit.vue';
import deleteIcon from '@/components/icons/IconDelete.vue';

// Modals
import PriceConfirmation from '@/components/openCall/priceConfirmation/priceConfirmation.vue';
import ConditionsConfirmation from '@/components/openCall/conditionsConfirmation/conditionsConfirmation.vue';

import 'vue-select/dist/vue-select.css';
import Vue from 'vue';
import vSelect from 'vue-select';
// import { fbind, async, timeout } from 'q';
Vue.component('v-select', vSelect);

// import mockData from '../_mockData'

export default {
  name: 'opencall',
  data() {
    return {
      section: 1,
      typeOfLogin: 0, /// 0 login, 1 registro
      showModal: false,
      openAddObra_portafolio: false,
      openAddObra_obra: false,
      ediciones_flag: false,
      obras_flag: false,
      portafolio_flag: false,
      convocatoriaIsOpen: true,
      selected: "",
      logout: false,

      finished: false,

      fileName: "",
      file: "",
      baseUrl_public: "",

      language: "es",
      // language: $root.language_option,

      user_name: "",

      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        suffix: '',
        precision: 2,
        masked: false
      },

      editing: {
        status: false,
        index: 0
      },

      ///////////////
      //// NOTIFICACIONES
      notification: {
        active: false,
        msg: "",
        time: 2000
      },

      ///////////////
      //// MODALES
      modalRecoverPasswordOpen: false,
      modalChangePassword: false,
      modalSendConvocatoria: false,
      modalPriceConfirmationVisible: false,
      modalConditionsConfirmationVisible: false,

      ///////////////
      //// SESSION
      sessionActive: false,

      ///////////////
      //// LOGIN
      dLogin: {
        email: "",
        password: "",
      },
      ///////////////
      //// REGISTER
      dRegister: {
        name: "",
        email: "",
        password: "",
        passwordConfirm: ""
      },
      ///////////////
      //// RECOVER PASSWORD
      dRecoverPassword: {
        email: ""
      },
      ///////////////
      //// PERSONAL DATA
      dPersonalData: {
        name: "",
        day: "",
        month: "",
        year: "",
        gender: "",
        nationality: "",
        city: "",
        contact_phone: "",
        web_page: "",
        instagram: ""
      },
      ///////////////
      //// CURRICULUM VITAE
      dCV: {
        curriculum_vitae: "",
        curriculum_vitae_semblance: "",
        curriculum_vitae_picture_file_real_name: "",
        curriculum_vitae_picture_file: "",
        curriculum_vitae_doc_file_real_name: "",
        curriculum_vitae_doc_file: "",
      },
      ///////////////
      //// PORTAFOLIO
      file_portafolio_rule: "",
      dPortafolios: [],
      dPortafolio: {
        file_real_name: "",
        file: "",
        link: "",
        password: "",
        title: "",
        tecnica: "",
        year: "",
        height: { "$numberDecimal": '' },
        width: { "$numberDecimal": '' },
        long: { "$numberDecimal": '' },
        weight: { "$numberDecimal": '' },
        description: ""
      },
      ///////////////
      //// OBRAS
      file_obra_rule: "",
      dObras: [],
      dObra: {
        file_real_name: "",
        file: "",
        link: "",
        password: "",
        title: "",
        tecnica: "",
        year: "",
        height: { "$numberDecimal": '' },
        width: { "$numberDecimal": '' },
        long: { "$numberDecimal": '' },
        weight: { "$numberDecimal": '' },
        description: "",
        price: { "$numberDecimal": '' },
        editions: false,
        editions_number: 0,
        editions_total_number: 0,
        editions_available_number: 0,
        editions_proofs_number: 0
      },


      ///////////////
      //// TRADUCCIONES
      languages: {
        'es': {
          convocatoriaClose: {
            title: "CONVOCATORIA CERRADA",
            intro: "Visita ",
            intro_2: " y descubre nuestras próximas fechas.",
            intro_link: "salonacme.com",
            subtitle: "¡Te esperamos la próxima edición!",
            note_1: "Cualquier duda o aclaración",
            note_2: "puedes ponerte en  ",
            contact: "Contacto ",
            note_3: "con el equipo de ACME."
          },
          responsive: {
            title: "CONVOCATORIA NO DISPONIBLE EN MOVIL",
            content: "Visita el sitio de Salón ACME desde tu Laptop o computadora de escritorio, para poder acceder a la convocatoria y llenar tu aplicacion."
          },
          main: {
            title: "CONVOCATORIA",
            final_button: "APLICAR"
          },
          nav: {
            sign_in: "Ingresar",
            profile: "Perfil",
            personal_data: "Datos Personales",
            obras: "Obras Propuestas",
            portafolio: "Portafolio",
            curriculum: "Curriculum Vitae",
            convocatoria: "Convocatoria",
            finished: "Aplicar"
          },

          login: { // login
            intro: "Ingresa a la plataforma de aplicación de Salón Acme. Las fechas de cierre de convocatoria podrás verlas en ",
            title: "INICIA SESIÓN",
            inputs: {
              email: "CORREO",
              password: "CONTRASEÑA"
            },
            tools: {
              one: "¿HAS OLVIDADO TU CONTRASEÑA?",
              two: "¿AUN NO TIENES CUENTA? ",
              tree: "CREA UNA CUENTA",
            },
            button: "ENTRAR"
          },

          register: { // register
            intro: "Ingresa a la plataforma de aplicación de Salón Acme. Las fechas de cierre de convocatoria podrás verlas en ",
            title: "CREAR UNA CUENTA",
            inputs: {
              name: "NOMBRE",
              email: "CORREO",
              password: "CONTRASEÑA",
              passwordConfirm: "CONFIRMAR CONTRASEÑA"
            },
            tools: {
              one: "¿YA TIENES UNA CUENTA?",
              two: "INICIA SESION"
            },
            button: "ENTRAR"
          },

          profile: {
            title: "Has ingresado a tu cuenta como:",
            tools: {
              one: "CAMBIAR MI CONTRASEÑA",
              two: "¿NO ES TU CUENTA?",
            },
            button: "CERRAR SESION"
          },

          personal_data: {
            intro: "Ingresa tus datos para poder estar en contacto contigo. Toda tu información está segura con nosotros. ",
            title: "DATOS PERSONALES",
            inputs: {
              name: "NOMBRE * ",
              name_2: "(COMO FIRMAS TUS OBRAS)",
              date: "FECHA DE NACIMIENTO",
              gender: "GÉNERO",
              nacionality: "NACIONALIDAD",
              city: "CIUDAD DE RESIDENCIA * ",
              contact_phone: "TELÉFONO DE CONTACTO * ",
              web_page: "PÁGINA WEB",
              web_page_2: "(EJEMPLO: 'WWW.TEST.COM')",
              instagram: "INSTAGRAM"
            },
            button: "GUARDAR"
          },

          curriculum: {
            title: "CURRICULUM VITAE",
            input: "Tu currículum debe incluir estudios y actividades artísticas más relevantes",
            button: "GUARDAR",
            placeholder: "MAX. 4000 CARACTERES", /// 700 PALABRAS
            placeholder2: "MAX. 1100 CARACTERES" /// - PALABRAS
          },

          portafolio: {
            title: "PORTAFOLIO",
            add_button: "AGREGAR OBRA",
            count: "OBRA(S) EN PORTAFOLIO",
            //empty
            empty_title: "PORTAFOLIO",
            empty_subtitle: "AGREGA TU PRIMER OBRA AL PORTAFOLIO 0/7",
            empty_button: "AGREGAR OBRA",
            // add
            intro: "Ingresa imagen y explicación de las obras que consideres más representativas de tu trabajo. Mínimo 5, máximo 7.",
            title_2: "PORTAFOLIO",
            count_2: "OBRA(S) EN PORTAFOLIO",
            inputs: {
              file: "ARCHIVO",
              file_2: "(JPG/JPEG 5MB MÁX)",
              video_comment: "EN CASO DE VIDEO",
              link: "LINK",
              password: "CONTRASEÑA",
              title: "TÍTULO * ",
              tecnica: "TÉCNICA * ",
              tecnica2: "(EN CASO DE ESCOGER OTRO ...)",
              year: "AÑO *",
              dimentions: "DIMENSIONES",
              dimentions_2: "(DEJAR EN BLANCO SI NO APLICA.)",
              height: "ALTO",
              long: "LARGO",
              width: "ANCHO",
              weight: "PESO",
              description: "EXPLICACIÓN CONCEPTUAL DE LA OBRA",
              description_2: "MAX. 1100 CARACTERES" //200 PALABRAS
            },
            buttons: {
              file: "CARGAR ARCHIVO",
              saveAndAdd: "GUARDAR Y AGREGAR OTRA OBRA",
              save: "GUARDAR"
            }
          },

          obras: {
            title: "OBRAS PROPUESTAS",
            add_button: "AGREGAR OBRA",
            count: "OBRA(S)",
            //empty
            empty_title: "OBRAS PROPUESTAS",
            empty_subtitle: "AGREGA TU PRIMER OBRA 0/3",
            empty_button: "AGREGAR OBRA",
            // add
            intro: "Puedes incluir de una a tres obras que quisieras mostrar en Salón ACME.  ¡Mucha suerte!",
            title_2: "OBRAS PROPUESTAS",
            count_2: "OBRA",
            inputs: {
              file: "ARCHIVO",
              file_2: "(JPG/JPEG 5MB MÁX)",
              video_comment: "EN CASO DE VIDEO",
              link: "LINK",
              password: "CONTRASEÑA",
              title: "TÍTULO * ",
              tecnica: "TÉCNICA * ",
              tecnica2: "(EN CASO DE ESCOGER OTRO ...)",
              year: "AÑO *",
              dimentions: "DIMENSIONES",
              dimentions_2: "(DEJAR EN BLANCO SI NO APLICA.)",
              height: "ALTO",
              long: "LARGO",
              width: "ANCHO",
              weight: "PESO",
              price: "PRECIO * ",
              // price_2: "(30% SALÓN ACME)",
              // price_2: "Precio máximo 30,000 MXN (25% Salón Acme en caso de venta)",
              price_2: "Máximo $100,000 MXN",
              price_3: "(35% Acme- 65% artista)",
              edition: "EDICIONES DISPONIBLES",
              edition_yes: "SI",
              edition_no: "NO",
              edition_number: "NÚMERO",
              description: "EXPLICACIÓN CONCEPTUAL DE LA OBRA",
              description_2: "MAX. 1100 CARACTERES" //200 PALABRAS
            },
            buttons: {
              file: "CARGAR ARCHIVO",
              saveAndAdd: "GUARDAR Y AGREGAR OTRA OBRA",
              save: "GUARDAR"
            }
          },

        },
        ////////////////////////
        'en': {
          convocatoriaClose: {
            title: "THE OPENCALL IS NOW CLOSED.",
            intro: "Visit ",
            intro_2: " and find out about our next dates.",
            intro_link: "salonacme.com",
            subtitle: "¡Stay tuned for results!",
            note_1: "Any doubts or comments ",
            note_2: " you can get in ",
            contact: "Contact",
            note_3: "  with Salon Acme's staff."
          },
          responsive: {
            title: "OPENCALL NOT AVAILABLE IN MOBILE",
            content: "Visit the ACME Salon site from your Laptop or desktop computer, to access the call and fill out your application."
          },
          main: {
            title: "OPEN CALL",
            final_button: "APPLY"
          },
          nav: {
            sign_in: "Sign In",
            profile: "Profile",
            personal_data: "Personal Information",
            obras: "Upload your work",
            portafolio: "Portfolio",
            curriculum: "Curriculum Vitae",
            convocatoria: "OpenCall", //// falta traduccion
            finished: "Apply"
          },

          login: { // login
            intro: "Log in to the application platform for Salon Acme. Deadline for your application will be posted in ",
            title: "SIGN IN",
            inputs: {
              email: "E-MAIL",
              password: "PASSWORD"
            },
            tools: {
              one: "FORGOT YOUR PASSWORD?",
              two: "YOU DON'T HAVE AN ACCOUNT?",
              tree: "CREATE ONE.",
            },
            button: "LOGIN"
          },

          register: { // register
            intro: "Log in to the application platform for Salon Acme. Deadline for your application will be posted in ",
            title: "CREATE AN ACCOUNT",
            inputs: {
              name: "Name",
              email: "E-MAIL",
              password: "PASSWORD",
              passwordConfirm: "CONFIRM PASSWORD"
            },
            tools: {
              one: "ALREADY HAVE AN ACCOUNT?",
              two: "LOG IN"
            },
            button: "CREATE"
          },

          profile: { //// FALTA
            title: "You have logged into your account as:",
            tools: {
              one: "CHANGE MY PASSWORD",
              two: "NOT YOUR ACCOUNT?",
            },
            button: "LOGOUT"
          },

          personal_data: {
            intro: "Enter your personal information so we can be in contact. All your information is safe with us.", // FALTA
            title: "PERSONAL INFORMATION",
            inputs: {
              name: "NAME * ",
              name_2: "(HOW DO YOU SIGN YOUR WORK?)",
              date: "BIRTH DATE",
              gender: "GENDER",
              nacionality: "NATIONALITY",
              city: "CITY OF RESIDENCE * ",
              contact_phone: "CONTACT PHONE NUMBER * ",
              web_page: "WEB PAGE",
              web_page_2: "(EXAMPLE: 'WWW.TEST.COM')",
              instagram: "INSTAGRAM"
            },
            button: "SAVE"
          },

          curriculum: {
            title: "CURRICULUM VITAE",
            input: "Make sure you include educational information and most relevant artistic activities.",
            placeholder: "MAX. 4000 CHARACTERS", // 700 WORDS characters
            placeholder2: "MAX. 1100 CHARACTERS", // - WORDS characters
            button: "SAVE"
          },

          portafolio: {
            title: "PORTFOLIO",
            add_button: "UPLOAD YOUR WORK",
            count: "ENTRY(S) IN PORTFOLIO", // FALTA
            //empty
            empty_title: "PORTFOLIO",
            empty_subtitle: "UPLOAD YOUR FIRST WORK TO PORTFOLIO 0/7",
            empty_button: "UPLOAD YOUR WORK",
            // add
            intro: "Enter an image and explanation of the works you consider most representative of your work. Minimum 5, maximum 7.",///// FALTA
            title_2: "PORTFOLIO",
            count_2: "ENTRY(S) IN PORTFOLIO",  // FALTA
            inputs: {
              file: "IMAGE",
              file_2: "(JPG/JPEG 5MB MÁX)",
              video_comment: "IN CASE OF VIDEO",
              link: "LINK",
              password: "PASSWORD",
              title: "TITLE * ",
              tecnica: "TECHNIQUE * ",
              tecnica2: "(IN CASE OF CHOOSING ANOTHER ...)",
              year: "YEAR *",
              dimentions: "DIMENSIONS",
              dimentions_2: "(LEAVE EMPTY IF DOESN'T APPLY)",
              height: "HEIGHT",
              long: "LENGHT",
              width: "WIDTH",
              weight: "WEIGHT",
              description: "SHORT STATEMENT OF THE ARTWORK",
              description_2: "MAX. 1100 CHARACTERS" //  200 WORDS CHARACTERS
            },
            buttons: {
              file: "UPLOAD IMAGE",
              saveAndAdd: "SAVE AND UPLOAD ANOTHER WORK",
              save: "SAVE"
            }
          },

          obras: {
            title: "UPLOAD YOUR WORK",
            add_button: "UPLOAD YOUR WORK",
            count: "ENTRY(S)", // FALTA
            //empty
            empty_title: "UPLOAD YOUR WORK",
            empty_subtitle: "ADD ENTRY 0/3", // FALTA
            empty_button: "UPLOAD YOUR WORK",
            // add
            intro: "You can include minimum one and maximum three works that you would like to show at Salon ACME.", // FALTA
            title_2: "UPLOAD YOUR WORK",
            count_2: "ENTRY", // FFALTA
            inputs: {
              file: "IMAGE",
              file_2: "(JPG/JPEG 5MB MÁX)",
              video_comment: "IN CASE OF VIDEO",
              link: "LINK",
              password: "PASSWORD",
              title: "TITLE * ",
              tecnica: "TECHNIQUE * ",
              tecnica2: "(IN CASE OF CHOOSING ANOTHER ...)",
              year: "YEAR *",
              dimentions: "DIMENSIONS",
              dimentions_2: "(LEAVE EMPTY IF DOESN'T APPLY)",
              height: "HEIGHT",
              long: "LENGHT",
              width: "WIDTH",
              weight: "WEIGHT",
              price: "PRICE * ",
              // price_2: "(30% SALÓN ACME)",
              // price_2: "Maximum price 30,000 MXN (25% for Salon ACME, in case of sale)",
              price_2: "Maximum $100,000 MXN",
              price_3: "(35% Acme- 65% artist)",
              edition: "AVAILABLE EDITIONS",
              edition_yes: "YES",
              edition_no: "NO",
              edition_number: "QUANTITY",
              description: "SHORT STATEMENT OF THE ARTWORK",
              description_2: "MAX. 1100 CHARACTERS" //  200 WORDS CHARACTERS
            },
            buttons: {
              file: "UPLOAD IMAGE",
              saveAndAdd: "SAVE AND UPLOAD ANOTHER ENTRY",
              save: "SAVE"
            }
          },

        },
      },


      nationalities: [
        'Afganistán',
        'Albania',
        'Alemania',
        'Andorra',
        'Angola',
        'Antigua y Barbuda',
        'Arabia Saudita',
        'Argelia',
        'Argentina',
        'Armenia',
        'Australia',
        'Austria',
        'Azerbaiyán',
        'Bahamas',
        'Bangladés',
        'Barbados',
        'Baréin',
        'Bélgica',
        'Belice',
        'Benín',
        'Bielorrusia',
        'Birmania',
        'Bolivia',
        'Bosnia y Herzegovina',
        'Botsuana',
        'Brasil',
        'Brunéi',
        'Bulgaria',
        'Burkina Faso',
        'Burundi',
        'Bután',
        'Cabo Verde',
        'Camboya',
        'Camerún',
        'Canadá',
        'Catar',
        'Chad',
        'Chile',
        'China',
        'Chipre',
        'Ciudad del Vaticano',
        'Colombia',
        'Comoras',
        'Corea del Norte',
        'Corea del Sur',
        'Costa de Marfil',
        'Costa Rica',
        'Croacia',
        'Cuba',
        'Dinamarca',
        'Dominica',
        'Ecuador',
        'Egipto',
        'El Salvador',
        'Emiratos Árabes Unidos',
        'Eritrea',
        'Eslovaquia',
        'Eslovenia',
        'España',
        'Estados Unidos',
        'Estonia',
        'Etiopía',
        'Filipinas',
        'Finlandia',
        'Fiyi',
        'Francia',
        'Gabón',
        'Gambia',
        'Georgia',
        'Ghana',
        'Granada',
        'Grecia',
        'Guatemala',
        'Guyana',
        'Guinea',
        'Guinea-Bisáu',
        'Guinea Ecuatorial',
        'Haití',
        'Honduras',
        'Hungría',
        'India',
        'Indonesia',
        'Irak',
        'Irán',
        'Irlanda',
        'Islandia',
        'Islas Marshall',
        'Islas Salomón',
        'Israel',
        'Italia',
        'Jamaica',
        'Japón',
        'Jordania',
        'Kazajistán',
        'Kenia',
        'Kirguistán',
        'Kiribati',
        'Kuwait',
        'Laos',
        'Lesoto',
        'Letonia',
        'Líbano',
        'Liberia',
        'Libia',
        'Liechtenstein',
        'Lituania',
        'Luxemburgo',
        'Macedonia del Norte',
        'Madagascar',
        'Malasia',
        'Malaui',
        'Maldivas',
        'Malí',
        'Malta',
        'Marruecos',
        'Mauricio',
        'Mauritania',
        'México',
        'Micronesia',
        'Moldavia',
        'Mónaco',
        'Mongolia',
        'Montenegro',
        'Mozambique',
        'Namibia',
        'Nauru',
        'Nepal',
        'Nicaragua',
        'Níger',
        'Nigeria',
        'Noruega',
        'Nueva Zelanda',
        'Omán',
        'Países Bajos',
        'Pakistán',
        'Palaos',
        'Panamá',
        'Papúa Nueva Guinea',
        'Paraguay',
        'Perú',
        'Polonia',
        'Portugal',
        'Reino Unido',
        'República Centroafricana',
        'República Checa',
        'República del Congo',
        'República Democrática del Congo',
        'República Dominicana',
        'República Sudafricana',
        'Ruanda',
        'Rumanía',
        'Rusia',
        'Samoa',
        'San Cristóbal y Nieves',
        'San Marino',
        'San Vicente y las Granadinas',
        'Santa Lucía',
        'Santo Tomé y Príncipe',
        'Senegal',
        'Serbia',
        'Seychelles',
        'Sierra Leona',
        'Singapur',
        'Siria',
        'Somalia',
        'Sri Lanka',
        'Suazilandia',
        'Sudán',
        'Sudán del Sur',
        'Suecia',
        'Suiza',
        'Surinam',
        'Tailandia',
        'Tanzania',
        'Tayikistán',
        'Timor Oriental',
        'Togo',
        'Tonga',
        'Trinidad y Tobago',
        'Túnez',
        'Turkmenistán',
        'Turquía',
        'Tuvalu',
        'Ucrania',
        'Uganda',
        'Uruguay',
        'Uzbekistán',
        'Vanuatu',
        'Venezuela',
        'Vietnam',
        'Yemen',
        'Yibuti',
        'Zambia',
        'Zimbabue'
      ],

      alertsMsg: {
        loading: {
          es: 'CARGANDO...',
          en: 'LOADING...'
        }
      }

    }
  },
  components: {
    recoverPassword,
    IconDownArrowSelect,
    changePassword,
    sendConvocatoria,
    origamiBird,
    editIcon,
    deleteIcon,
    PriceConfirmation,
    ConditionsConfirmation,
  },
  beforeCreate: function () {
    if (this.$session.exists()) {
      this.sessionActive = true;
    }
  },
  methods: {
    closeModalPriceConfirmation() {
      this.modalPriceConfirmationVisible = false;
      this.resolvePromise();
    },
    closeModalConditionsConfirmation(logout = false) {
      this.modalConditionsConfirmationVisible = false;
      if( logout ) {
        this.logout = true; // flag active
      }
      this.resolvePromise(); // return to logoutUser function
    },
    notify(msg, time, type='warning') {
      if(time == null) {
        time = 3000
      }

      this.notification.type = type;
      this.notification.msg = msg;
      this.notification.time = time;
      this.notification.active = true;
      
      setTimeout(() => { 
        this.notification.active = false; 
        this.notification.type = 'warning';
      }, time)
    },
    changeLanguage(language) {
      this.language = language;
    },

    // finished Data
    ending() {

      (async () => {
        var rawResponse = await fetch(this.$root.baseUrl_api+'ending', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            _id: this.$session.get('_id')
          })
        });
        const content = await rawResponse.json();
        if (content.status) {
          this.modalSendConvocatoria = true;
        } else {
          this.notify("AUN TE FALTAN CAMPOS POR COMPLETAR");
          // iluminar menu de los datos faltantes
          if (!content.res.personal_data) {
            var element1 = document.getElementById("menu_personal_data");
            element1.classList.add("is-danger--text");
            // element1.classList.add("is-danger--input");
            setTimeout(() => {
              element1.classList.remove("is-danger--text");
              // element1.classList.remove("is-danger--input");
            }, 3000);
          }
          if (!content.res.curriculum) {
            var element2 = document.getElementById("menu_curriculum");
            element2.classList.add("is-danger--text");
            // element2.classList.add("is-danger--input");
            setTimeout(() => {
              element2.classList.remove("is-danger--text");
              // element2.classList.remove("is-danger--input");
            }, 3000);
          }
          if (!content.res.portafolio) {
            var element3 = document.getElementById("menu_portafolio");
            element3.classList.add("is-danger--text");
            // element3.classList.add("is-danger--input");
            setTimeout(() => {
              element3.classList.remove("is-danger--text");
              // element3.classList.remove("is-danger--input");
            }, 3000);
          }
          if (!content.res.obras) {
            var element4 = document.getElementById("menu_obras");
            element4.classList.add("is-danger--text");
            // element4.classList.add("is-danger--input");
            setTimeout(() => {
              element4.classList.remove("is-danger--text");
              // element4.classList.remove("is-danger--input");
            }, 3000);
          }
        }
      })();

    },
    updateFinished() {

      (async () => {
        var rawResponse = await fetch(this.$root.baseUrl_api+'updateFinished', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            _id: this.$session.get('_id'),
            email: this.$session.get('email')
          })
        });
        const content = await rawResponse.json();
        if (content.status) {

          this.$session.set("finished", true);
          this.finished = true;
          // this.sendConvocatoria = false;
          this.modalSendConvocatoria = false;
          this.notify("EXITO!, CONVOCATORIA ENVIADA");
          setTimeout(() => { this.section = 6; }, 1500);

        }
      })();

    },
    //
    changeSection(section) {
      this.section = section;
      this.errors.clear();
    },
    changeTypeOfLogin(type) {
      this.typeOfLogin = type;
    },
    sendConvocatoria() {
      this.modalSendConvocatoria = true;
    },
    saveUser(scope) {
      this.$validator.validateAll(scope).then((result) => {

        if (result) {
          this.dRegister.email = this.dRegister.email.toLowerCase();
          (async () => {
            const rawResponse = await fetch(this.$root.baseUrl_api+'new_opencall', {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ user: this.dRegister })
            });
            const content = await rawResponse.json();
            ///// Action:
            if (content.status == true) {
              this.dLogin.email = this.dRegister.email;
              this.dLogin.password = this.dRegister.password;
              await this.loginUser();
              this.section = 2;

              this.clearPersonalData();
              this.errors.clear();

              setTimeout(() => {
                this.dRegister.name = "";
                this.dRegister.email = "";
                this.dRegister.password = "";
                this.dRegister.passwordConfirm = "";
              }, 1000);

            } else {
              console.log("error credenciales login");
              this.notify(content.msg.toUpperCase());
            }

          })();
        }

      });
    },

    // LOGIN
    loginUser(scope) {

      this.clearPersonalData();
      this.errors.clear();

      if (this.$session.exists()) {
        this.sessionActive = false;
        this.logoutUser();
        // return;
      }
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          this.dLogin.email = this.dLogin.email.toLowerCase();
          ////////// LOGIN  FUNCTION //////////

          (async () => {
            const rawResponse = await fetch(this.$root.baseUrl_api+'login', {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ user: this.dLogin })
            });

            const content = await rawResponse.json();
            
            // Modal Conditions Confirmation
            if(content.status === true && !content.result.conditions) {
              this.modalConditionsConfirmationVisible = true;
              await new Promise((resolve) => {
                this.resolvePromise = resolve;
              });
              this.modalConditionsConfirmationVisible = false;

              if( this.logout ) {
                this.logout = false;
                this.logoutUser();
                return
              } else {
                // Update flag in DB
                await fetch(this.$root.baseUrl_api+'conditions', {
                  method: 'PUT',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({ _id: content.result._id })
                });
              }
            }


            ///// Action:
            /*========= START SESSION =========*/
            if (content.status === true && content.result.email === this.dLogin.email) {
              this.$session.start();
              this.$session.set("_id", content.result._id);
              this.$session.set("name", content.result.name);
              this.$session.set("email", content.result.email);
              this.$session.set("finished", content.result.finished);
              this.$session.set("edition_id", content.result.edition_id);
              this.$session.set("_aws_url", content.result._aws_url);
              this.$session.set("_aws_url_file", ( content.result._aws_url + content.result.edition_id + "/" ) );
              this.$session.set("conditions", ( content.result.conditions ) );
              // this.$session.get('_aws_url_file')

              this.finished = content.result.finished;
              await this.loadAllData();
              if (this.$session.exists()) {
                this.dLogin.email = "";
                this.dLogin.password = "";
                this.sessionActive = true;
              } else {
                alert("Error");
              }

            } else {
              console.log('notify error');
              this.notify((this.$root.language_option=='es'?"FALLO EN LA AUTENTIFICACIÓN":"AUTHENTICATION FAILED"), null, 'error');
            }
          })();

        } else {
          this.notify(this.$root.language_option=='es'?"INGRESA LOS DATOS CORRESPONDIENTES":"ENTER THE CORRESPONDING DATA");
        }
      });
    },
    logoutUser() {
      this.$session.destroy();
      this.$session.clear();
      this.sessionActive = false;
      this.errors.clear();
      this.$router.push('/');
      this.user_name = "";
    },

    // LOAD ALL DATA
    loadAllData() {
      /** Load ultimate session */
      this.finished = this.$session.get("finished");

      /* LOAD PERSONAL DATA */
      (async () => {
        var _id = this.$session.get("_id");
        if (typeof _id === "undefined")
          return;

        this.user_name = this.$session.get("name");

        const rawResponse = await fetch(this.$root.baseUrl_api+'personal_data', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ _id: _id })
        });
        const content = await rawResponse.json();
        ///// Action:
        if (content.status == true && typeof content.result !== "undefined") {
          this.dPersonalData = content.result;
        } else {
          console.log(content.msg);
        }

      })();
      /* LOAD CURRICULUM VITAE */
      (async () => {
        var _id = this.$session.get("_id");
        if (typeof _id === "undefined")
          return;
        const rawResponse = await fetch(this.$root.baseUrl_api+'curriculum_vitae', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ _id: _id })
        });
        const content = await rawResponse.json();
        // console.log(content.result);
        ///// Action:
        if (content.status == true) {
          if(content.result != "")
            this.dCV = content.result;
        } else {
          console.log(content.msg);
        }

      })();
      /* LOAD PORTAFOLIO */
      this.loadPortafolio();
      /* LOAD OBRA */
      this.loadObra();
    },
    loadPortafolio() {

      /* LOAD PORTAFOLIO */
      (async () => {
        var _id = this.$session.get("_id");
        if (typeof _id === "undefined")
          return;
        const rawResponse = await fetch(this.$root.baseUrl_api+'portafolio', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ _id: _id })
        });
        const content = await rawResponse.json();
        ///// Action:
        if (content.status == true && typeof content.result !== "undefined") {
          // console.log(content.result);
          if ((content.result).length > 0) { //// Si contiene portafolios
            this.portafolio_flag = 1;
            this.dPortafolios = content.result;
            this.errors.clear();
          } else {
            this.portafolio_flag = 0;
            this.dPortafolios = [];
          }
          // this.dPortafolio.file = content.result.file;
          // this.dPortafolio.link = content.result.link;
          // this.dPortafolio.password = content.result.password;
          // this.dPortafolio.title = content.result.title;
          // this.dPortafolio.tecnica = content.result.tecnica;
          // this.dPortafolio.year = content.result.year;
          // this.dPortafolio.height = content.result.height;
          // this.dPortafolio.width = content.result.width;
          // this.dPortafolio.long = content.result.long;
          // this.dPortafolio.weight = content.result.weight;
          // this.dPortafolio.description = content.result.description;
        } else {
          console.log(content.msg);
          this.dPortafolios = [];
        }

      })();
    },
    loadObra() {
      /* LOAD OBRA */
      (async () => {
        var _id = this.$session.get("_id");
        if (typeof _id === "undefined")
          return;
        const rawResponse = await fetch(this.$root.baseUrl_api+'obra', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ _id: _id })
        });
        const content = await rawResponse.json();
        ///// Action:
        if (content.status == true && typeof content.result !== "undefined") {
          // console.log(content.result);
          if ((content.result).length > 0) { //// Si contiene obras
            this.obras_flag = 1;
            this.dObras = content.result;
            this.errors.clear();
          } else {
            this.obras_flag = 0;
            this.dObras = [];
          }
          // this.dObra.file = content.result.file;
          // this.dObra.link = content.result.link;
          // this.dObra.password = content.result.password;
          // this.dObra.title = content.result.title;
          // this.dObra.tecnica = content.result.tecnica;
          // this.dObra.year = content.result.year;
          // this.dObra.height = content.result.height;
          // this.dObra.width = content.result.width;
          // this.dObra.long = content.result.long;
          // this.dObra.weight = content.result.weight;
          // this.dObra.description = content.result.description;
        } else {
          this.dObras = [];
        }

      })();
    },
    personalData(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          (async () => {
            var _id = this.$session.get("_id");
            if (typeof _id === "undefined")
              return;
            const rawResponse = await fetch(this.$root.baseUrl_api+'personal_data', {
              method: 'PUT',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ _id: _id, personal_data: this.dPersonalData })
            });
            const content = await rawResponse.json();
            ///// Action:
            if (content.status == true) {
              this.notify("DATOS GUARDADOS EXITOSAMENTE");

              if (!this.finished) {
                this.changeSection(5);
              } else {
                this.changeSection(6);
              }
            } else {
              console.log("error credenciales login");
            }

          })();
        } else {
          this.notify("Faltan datos");
        }
      });
    },

    // CV
    curriculumVitae(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          (async () => {
            let _id = this.$session.get("_id");
            if (typeof _id === "undefined")
              return;

            // file picture
            let input = document.getElementById('opencall__cv_picture_file');
            input = (input && input.files)?input.files[0]:false;
            let auxInput = "";
            let data = new FormData();

            if (
              input && 
              input['type'] && 
              (input['type'].split('/')[0] === 'image')
            ) {
              /** Check only jpeg jpg png pdf gif tiff  */
              if(input['type'].indexOf('jpeg') == -1 || input['type'].indexOf('image') == -1) {
                this.notify("Solo aceptamos formato JPG");
                return
              }
              
              if ( input && typeof input.size !== 'undefined' ) {
                if ((input.size < 2242880)) //// adjuntamos la imagen
                {
                  auxInput = new File([input], 'new_name.' + (input['type'].split('/')[1]), { type: input.type });
                  data.append('file_picture', auxInput);
                } else {
                  document.getElementById("opencall__cv_picture_file").value = "";
                  this.fileName = "";
                  this.notify("ARCHIVO SUPERA LOS 2MB, DEBE SER MENOR.");
                  return;
                }
                this.dCV.curriculum_vitae_picture_file_real_name = this.fileName; // no edit, entonces se asigna el nuevo nombre
              }
            }
            // end file picture

            // file doc
            input = document.getElementById('opencall__cv_doc_file');
            input = (input && input.files)?input.files[0]:false;
            auxInput = "";

            if (
              input && 
              input['type']
            ) {
              /** Check only pdf  */
              if(input['type'].indexOf('pdf') == -1) {
                this.notify("Solo aceptamos formato PDF");
                return
              }

              if ( input && typeof input.size !== 'undefined' ) {
                if ((input.size < 2242880)) //// adjuntamos la imagen
                {
                  auxInput = new File([input], 'new_name.' + (input['type'].split('/')[1]), { type: input.type });
                  data.append('file_doc', auxInput);
                } else {
                  document.getElementById("opencall__cv_doc_file").value = "";
                  this.fileName = "";
                  this.notify("ARCHIVO SUPERA LOS 2MB, DEBE SER MENOR.");
                  return;
                }
                this.dCV.curriculum_vitae_doc_file_real_name = this.fileName; // no edit, entonces se asigna el nuevo nombre
              }
            }
            // end file picture
            data.append('data', JSON.stringify({ _id: _id, curriculum_vitae: this.dCV }));

            const rawResponse = await fetch(this.$root.baseUrl_api+'curriculum_vitae ', {
              method: 'PUT',
              // headers: {
              //   'Accept': 'application/json',
              //   'Content-Type': 'application/json'
              // },
              // body: JSON.stringify({ _id: _id, curriculum_vitae: this.dCV })
              body: data
            });
            const content = await rawResponse.json();
            ///// Action:
            if (content.status == true) {
              this.notify("CURRICULUM GUARDADO EXITOSAMENTE");

              if (!this.finished) {
                this.changeSection(7);
                this.loadAllData();
              }
            } else {
              console.log("error credenciales login");
            }
          })();
        }
      });
    },

    // PORTAFOLIO
    portafolio(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          (async () => {
            var _id = this.$session.get("_id");
            if (typeof _id === "undefined")
              return;

            var isEdit = false;
            if (typeof this.dPortafolio._id !== "undefined") {
              isEdit = true;
            }


            var input = document.getElementById('opencall__p_file');
            input = input.files[0];
            var auxInput = "";

            if (input && input['type'] && ((input['type'].split('/')[0] === 'image' || input['type'].split('/')[1] === 'pdf'))
              || isEdit
              || this.dPortafolio.link != "") /// validamos la imagen o si es edicion y si viene sol un link
            // if( (input) || isEdit) /// validamos la imagen o si es edicion
            {
              /** Check only jpeg jpg png pdf gif tiff  */
              if(input && input['type'] && (input['type'].indexOf('jpeg') == -1 || input['type'].indexOf('image') == -1)) {
                this.notify("Solo aceptamos formato JPG");
                return
              }


              var data = new FormData();
              /// !isEdit &&
              if (input && typeof input.size !== 'undefined') {
                if ((input.size < 5242880)) //// adjuntamos la imagen
                {
                  auxInput = new File([input], 'new_name.' + (input['type'].split('/')[1]), { type: input.type });
                  data.append('file', auxInput);
                } else {
                  document.getElementById("opencall__p_file").value = "";
                  this.fileName = "";
                  this.notify("ARCHIVO SUPERA LOS 5MB, DEBE SER MENOR.");
                  return;
                }
                this.dPortafolio.file_real_name = this.fileName; // no edit, entonces se asigna el nuevo nombre
              }
              // data.append('data', {_id: _id, portafolio: this.dPortafolio});

              data.append('data', JSON.stringify({ _id: _id, portafolio: this.dPortafolio }));

              if (isEdit) {
                this.notify(this.alertsMsg.loading[this.language]);
                var rawResponse = await fetch(this.$root.baseUrl_api+'editPortafolio', {
                  method: 'PUT',
                  body: data
                  // body: JSON.stringify(data)
                }).then(async response => {
                  response = await response.json();
                  if (response.status) {
                    this.notify(
                      this.$root.language_option=='es'?
                      'OBRA GUARDADA EXITOSAMENTE':
                      'WORK SAVED SUCCESSFULLY'
                    );
                  } else {
                    this.notify(" ! ERROR, INTENTA DE NUEVO Y PROCURA SUBIR UN ARCHIVO VALIDO... ! ");
                  }
                });
              } else {
                this.notify(this.alertsMsg.loading[this.language]);
                var rawResponse = await fetch(this.$root.baseUrl_api+'portafolio', {
                  method: 'PUT',
                  body: data
                  // body: JSON.stringify(data)
                }).then(async response => {
                  response = await response.json();
                  if (response.status) {
                    this.notify(
                      this.$root.language_option=='es'?
                      'OBRA GUARDADA EXITOSAMENTE':
                      'WORK SAVED SUCCESSFULLY'
                    );
                  } else {
                    this.notify(" ! ERROR, INTENTA DE NUEVO Y PROCURA SUBIR UN ARCHIVO VALIDO... ! ");
                  }
                });
              }
              // if(rawResponse.status == 200)
              // {
              //   this.loadPortafolio();
              // }
              this.loadPortafolio();
              // const rawResponse = await fetch(this.$root.baseUrl_api+'testFile', {
              //   method: 'POST',
              //   headers: {
              //     // 'Accept': 'application/json',
              //     // 'Content-Type': 'application/json'
              //   },
              //   // body: data
              //   body: JSON.stringify(data)
              //   // body: JSON.stringify(body)
              // });
              // const content = await rawResponse.json();
              // ///// Action:
              // if(content.status == true){

              // }else{
              //   console.log("error credenciales login");
              // }



            } else {
              /// no es una imagen
              alert("Solo imagenes JPG");
              return;
            }

          })();
        } else {
          this.notify(
            this.$root.language_option=='es'?
            'Campos sin llenar, completa los campos obligatorios en rojo.':
            'Unfilled fields, complete the required fields in red.'
          );
        }
      });
    },
    portafolioSaveAndAdd(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          let input = document.getElementById('opencall__p_file');
          input = input.files[0];

          if(input && input['type'].indexOf('jpeg') == -1 || input['type'].indexOf('image') == -1) {
            this.notify("Solo aceptamos formato JPG");
            return
          }

          this.$refs.opencall__content.scrollTop = 0;
          this.openAddObra_portafolio = 1;
          setTimeout(() => {
            this.clearPortafolio();
            this.editing.status = false;
            this.errors.clear();
            setTimeout(() => {
              this.errors.clear();
            }, 50);
          }, 500);
        } else {
          this.notify(
            this.$root.language_option=='es'?
            'Campos sin llenar, completa los campos obligatorios en rojo.':
            'Unfilled fields, complete the required fields in red.'
          );
        }
      });
    },
    portafolioJustSave(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          let input = document.getElementById('opencall__p_file');
          input = input.files[0];

          if(input && (input['type'].indexOf('jpeg') == -1 || input['type'].indexOf('image') == -1)) {
            this.notify("Solo aceptamos formato JPG");
            return
          }

          if (!this.finished) {
            this.changeSection(3);
          }

          this.openAddObra_portafolio = 0;
          setTimeout(() => { this.clearPortafolio(); }, 1000);
        } else {
          this.notify(
            this.$root.language_option=='es'?
            'Campos sin llenar, completa los campos obligatorios en rojo.':
            'Unfilled fields, complete the required fields in red.'
          );
        }
      });
    },
    clearPortafolio() {
      document.getElementById("opencall__p_file").value = "";
      delete this.dPortafolio._id;
      this.dPortafolio.file = "";
      this.dPortafolio.file_real_name = "";
      this.dPortafolio.link = "";
      this.dPortafolio.password = "";
      this.dPortafolio.title = "";
      this.dPortafolio.tecnica = "";
      this.dPortafolio.year = "";
      this.dPortafolio.height = { "$numberDecimal": '' };
      this.dPortafolio.width = { "$numberDecimal": '' };
      this.dPortafolio.long = { "$numberDecimal": '' };
      this.dPortafolio.weight = { "$numberDecimal": '' };
      this.dPortafolio.description = "";
      this.fileName = "";
      this.errors.clear();
    },
    addNewPortafolio() {
      this.clearPortafolio();
      this.editing.status = false;
      this.openAddObra_portafolio = true;
      this.file_portafolio_rule = 'required';
    },
    editPortafolio(index) {
      this.editing.status = true;
      this.editing.index = index + 1;
      this.file_portafolio_rule = "";
      // this.dPortafolio = this.dPortafolios[index];
      this.dPortafolio = JSON.parse(JSON.stringify(this.dPortafolios[index]));
      this.openAddObra_portafolio = 1;


      // (async () => {
      //   var user_id = this.$session.get("_id");
      //   if(typeof _id === "undefined" || typeof user_id === "undefined")
      //     return;
      //   const rawResponse = await fetch(this.$root.baseUrl_api+'editPortafolio ', {
      //     method: 'POST',
      //     headers: {
      //       'Accept': 'application/json',
      //       'Content-Type': 'application/json'
      //     },
      //     body: JSON.stringify({user_id: user_id, portafolio_id: _id})
      //   });
      //   if(rawResponse.status == 200)
      //     this.loadPortafolio();
      // })();
    },
    deletePortafolio(_id) {

      (async () => {
        var user_id = this.$session.get("_id");
        if (typeof _id === "undefined" || typeof user_id === "undefined")
          return;
        const rawResponse = await fetch(this.$root.baseUrl_api+'deletePortafolio ', {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ user_id: user_id, portafolio_id: _id })
        });
        if (rawResponse.status == 200)
          this.loadPortafolio();
      })();

    },
    onFileClick() { /** CLICK EVENT FILE */
      document.getElementById("opencall__p_file").click();
    },

    onFileClick_CV_Picture() { /** CLICK EVENT FILE */
      document.getElementById("opencall__cv_picture_file").click();
    },

    onFileClick_CV_Doc() { /** CLICK EVENT FILE */
      document.getElementById("opencall__cv_doc_file").click();
    },

    onFileChange(id_file, from) { /** OBTENER NAME */
      console.log("onFileChange-");
      console.log(id_file,from);

      // file size check PORTAFOLIO
      var input_temp_p = document.getElementById('opencall__p_file');
      input_temp_p = input_temp_p.files[0];
      if (input_temp_p && (input_temp_p.size > 5242880)) //// adjuntamos la imagen
      {
        document.getElementById("opencall__p_file").value = "";
        this.fileName = "";
        this.notify("ARCHIVO SUPERA LOS 5MB, DEBE SER MENOR.");
        return;
      }
      // end file size check

      // file size check OBRAS
      var input_temp_o = document.getElementById('opencall__o_file');
      input_temp_o = input_temp_o.files[0];
      if (input_temp_o && (input_temp_o.size > 5242880)) //// adjuntamos la imagen
      {
        document.getElementById("opencall__o_file").value = "";
        this.fileName = "";
        this.notify("ARCHIVO SUPERA LOS 5MB, DEBE SER MENOR.");
        return;
      }
      // end file size check

      // file size check CV picture
      let input_temp_cv = document.getElementById('opencall__cv_picture_file');
      input_temp_cv = (input_temp_cv && input_temp_cv.files[0])?input_temp_cv.files[0]:false;
      if (input_temp_cv && (input_temp_cv.size > 2242880)) //// adjuntamos la imagen
      {
        document.getElementById("opencall__cv_picture_file").value = "";
        this.fileName = "";
        this.notify("ARCHIVO SUPERA LOS 2MB, DEBE SER MENOR.");
        return;
      }
      // end file size check

      // file size check CV doc
      let input_temp_cv_doc = document.getElementById('opencall__cv_doc_file');
      input_temp_cv_doc = (input_temp_cv_doc && input_temp_cv_doc.files[0])?input_temp_cv_doc.files[0]:false;
      if (input_temp_cv_doc && (input_temp_cv_doc.size > 2242880)) //// adjuntamos la imagen
      {
        document.getElementById("opencall__cv_doc_file").value = "";
        this.fileName = "";
        this.notify("ARCHIVO SUPERA LOS 2MB, DEBE SER MENOR.");
        return;
      }
      // end file size check

      var fullPath = document.getElementById(id_file).value;
      if (fullPath) {
        var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
        var filename_ = fullPath.substring(startIndex);
        if (filename_.indexOf('\\') === 0 || filename_.indexOf('/') === 0) {
          filename_ = filename_.substring(1);
        }
        fullPath = "";
        startIndex = "";
        this.fileName = filename_;
        // filename = "";
        this.dPortafolio.file_real_name = this.fileName;
        this.dObra.file_real_name = this.fileName;
        if ( from == "portafolio" ) {
          this.dPortafolio.file_real_name = this.fileName;
        } 
        if( from == "obra" ) {
          this.dObra.file_real_name = this.fileName;
        }
        if (from == "cv_picture") {
          this.dCV.curriculum_vitae_picture_file_real_name = this.fileName;
        }
        if (from == "cv_doc") {
          this.dCV.curriculum_vitae_doc_file_real_name = this.fileName;
        }
      }

    },
    // OBRAS
    obra(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (!result) // Valida campos obligatorios
        {
          this.notify(
            this.$root.language_option=='es'?
            'Campos sin llenar, completa los campos obligatorios en rojo.':
            'Unfilled fields, complete the required fields in red.'
            );
          return;
        }

        if (this.errors.obra_form_price == true) // Valida si el precio es superado
        {
          // this.notify
          return;
        }

        if (this.dObra.price["$numberDecimal"] < 1) {
          this.notify(
            this.$root.language_option=='es'?
            'EL PRECIO TIENE QUE SER MAYOR A 0.':
            'THE PRICE HAS TO BE SET'
          );
          return;
        }

        if (result) {
          (async () => {
            var _id = this.$session.get("_id");
            if (typeof _id === "undefined")
              return;

            var isEdit = false;
            if (typeof this.dObra._id !== "undefined") {
              isEdit = true;
            }

            var input = document.getElementById('opencall__o_file');
            input = input.files[0];
            var auxInput = "";

            if ((input && input['type'] && (input['type'].split('/')[0] === 'image' || input['type'].split('/')[1] === 'pdf'))
              || isEdit
              || this.dObra.link != "") /// validamos la imagen o si es edicion
            {
              if(input && (input['type'].indexOf('jpeg') == -1 || input['type'].indexOf('image') == -1)) {
                this.notify("Solo aceptamos formato JPG");
                return;
              }

              var data = new FormData();
              /// !isEdit &&
              if (input && typeof input.size !== 'undefined') {
                // if((input && input['type'].split('/')[0] === 'image')) //// adjuntamos la imagen
                if ((input.size < 5242880)) //// adjuntamos la imagen
                {
                  auxInput = new File([input], 'new_name.' + (input['type'].split('/')[1]), { type: input.type });
                  data.append('file', auxInput);
                }
                else {
                  document.getElementById("opencall__o_file").value = "";
                  this.fileName = "";
                  this.notify("ARCHIVO SUPERA LOS 5MB, DEBE SER MENOR.");
                  return;
                }
                this.dObra.file_real_name = this.fileName;
              }


              data.append('data', JSON.stringify({ _id: _id, obras: this.dObra }));

              if (isEdit) {
                this.notify(this.alertsMsg.loading[this.language]);
                var rawResponse = await fetch(this.$root.baseUrl_api+'editObra', {
                  method: 'PUT',
                  body: data
                }).then(async response => {
                  response = await response.json();
                  if (response.status) {
                    this.notify(
                      this.$root.language_option=='es'?
                      'OBRA GUARDADA EXITOSAMENTE':
                      'WORK SAVED SUCCESSFULLY'
                    );
                  } else {
                    this.notify(" ¡ ERROR, INTENTA DE NUEVO Y PROCURA SUBIR UN ARCHIVO VÁLIDO ! ");
                  }
                });
              } else {
                this.notify(this.alertsMsg.loading[this.language]);
                var rawResponse = await fetch(this.$root.baseUrl_api+'obra', {
                  method: 'PUT',
                  body: data
                }).then(async response => {
                  response = await response.json();
                  if (response.status) {
                    this.notify(
                      this.$root.language_option=='es'?
                      'OBRA GUARDADA EXITOSAMENTE':
                      'WORK SAVED SUCCESSFULLY'
                    );
                  } else {
                    this.notify(" ¡ ERROR, INTENTA DE NUEVO Y PROCURA SUBIR UN ARCHIVO VÁLIDO ! ");
                  }
                });
              }

              // if(rawResponse.status == 200)
              // {
              //   this.loadObra();
              // }

              this.loadObra();

            } else {
              /// no es una imagen
              alert("no es una imagen");
              return;
            }

          })();
        }
      });
    },
    async obraSaveAndAdd(scope) {
      this.$validator.validateAll(scope).then(async (result) => {
        if (!result) // Valida campos obligatorios
        {
          this.notify(
            this.$root.language_option=='es'?
            'Campos sin llenar, completa los campos obligatorios en rojo.':
            'Unfilled fields, complete the required fields in red.'
            );
          return;
        }

        if (this.dObra.price["$numberDecimal"] < 1) {
          this.notify(
            this.$root.language_option=='es'?
            'EL PRECIO TIENE QUE SER MAYOR A 0.':
            'THE PRICE HAS TO BE SET'
          );
          return;
        }

        if (this.errors.obra_form_price == true) // Valida si el precio es superado
          return;

        // price modal confirm
        this.modalPriceConfirmationVisible = true;
        await new Promise((resolve) => {
          this.resolvePromise = resolve;
        });

        let input = document.getElementById('opencall__o_file');
        input = input.files[0];
        if(input['type'].indexOf('jpeg') == -1 || input['type'].indexOf('image') == -1) {
          this.notify("Solo aceptamos formato JPG");
          return;
        }

        if (result) {
          // document.getElementById('')
          // document.getElementsByClassName('opencall__content').scrollTop(0);
          this.$refs.opencall__content.scrollTop = 0;
          this.openAddObra_obra = 1;
          setTimeout(() => {
            this.clearObra();
            this.editing.status = false;
            this.errors.clear();
            setTimeout(() => {
              this.errors.clear();
            }, 50);
          }, 500);
        }
      });
    },
    async obraJustSave(scope) {
      try {
        this.$validator.validateAll(scope).then(async (result) => {
        if (!result) // Valida campos obligatorios
        {
          this.notify(
            this.$root.language_option=='es'?
            'Campos sin llenar, completa los campos obligatorios en rojo.':
            'Unfilled fields, complete the required fields in red.'
            );
          return;
        }

        if (this.dObra.price["$numberDecimal"] < 1) {
          this.notify(
            this.$root.language_option=='es'?
            'EL PRECIO TIENE QUE SER MAYOR A 0.':
            'THE PRICE HAS TO BE SET'
          );
          return;
        }
  
          let input = document.getElementById('opencall__o_file');
          input = input.files[0];

          if(input && input['type'] && (input['type'].indexOf('jpeg') == -1 || input['type'].indexOf('image') == -1)) {
            this.notify("Solo aceptamos formato JPG");
            return;
          }

          if (this.errors.obra_form_price == true) // Valida si el precio es superado
            return;

          // price modal confirm
          this.modalPriceConfirmationVisible = true;
          await new Promise((resolve) => {
            this.resolvePromise = resolve;
          });

          if (result) {
            if (!this.finished) {
              this.changeSection(4);
            }
  
            this.openAddObra_obra = 0;
            setTimeout(() => { this.clearObra(); }, 1000);
          }
        });
      } catch (error) {
        
      }
    },
    clearObra() {
      document.getElementById("opencall__o_file").value = "";
      delete this.dObra._id;
      this.dObra.file = "";
      this.dObra.file_real_name = "";
      this.dObra.link = "";
      this.dObra.password = "";
      this.dObra.title = "";
      this.dObra.tecnica = "";
      this.dObra.year = "";
      this.dObra.height = { "$numberDecimal": '' };
      this.dObra.width = { "$numberDecimal": '' };
      this.dObra.long = { "$numberDecimal": '' };
      this.dObra.weight = { "$numberDecimal": '' };
      this.dObra.description = "";
      this.dObra.price = { "$numberDecimal": '' };
      this.dObra.editions = "";
      this.dObra.editions_number = "";
      this.fileName = "";
      this.errors.clear();
    },
    addNewObra() {
      this.clearObra();
      this.editing.status = false;
      this.openAddObra_obra = true;
      this.file_obra_rule = 'required';
    },
    editObra(index) {
      this.editing.status = true;
      this.editing.index = index + 1;
      this.file_obra_rule = "";
      this.dObra = JSON.parse(JSON.stringify(this.dObras[index]));
      if (this.dObra.price.$numberDecimal >= 0)
        this.dObra.price.$numberDecimal = this.dObra.price.$numberDecimal * 1;
      this.openAddObra_obra = true;
      // setTimeout(() => {
      //   if(this.dObra.price.$numberDecimal != this.dObras[index].price.$numberDecimal)
      //     this.dObra.price.$numberDecimal = this.dObra.price.$numberDecimal*100;
      // }, 600);
    },
    deleteObra(_id) {

      (async () => {
        var user_id = this.$session.get("_id");
        if (typeof _id === "undefined" || typeof user_id === "undefined")
          return;
        const rawResponse = await fetch(this.$root.baseUrl_api+'deleteObra ', {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ user_id: user_id, obra_id: _id })
        });
        if (rawResponse.status == 200)
          this.loadObra();
      })();

    },
    onFileClickObra() { /** CLICK EVENT FILE */
      document.getElementById("opencall__o_file").click();
    },

    clearPersonalData() {
      this.dPersonalData.name = "";
      this.dPersonalData.day = "";
      this.dPersonalData.month = "";
      this.dPersonalData.year = "";
      this.dPersonalData.gender = "";
      this.dPersonalData.nationality = "";
      this.dPersonalData.city = "";
      this.dPersonalData.contact_phone = "";
      this.dPersonalData.web_page = "";
      this.dPersonalData.instagram = "";
    },

    theMounted() {
      if (typeof this.$session.get('_id') !== 'undefined') // Reactiva la session en caso de recargar la pagina
      {
        this.sessionActive = true;
        this.loadAllData();
      } else {
      }
    }
  },
  watch: {
    // whenever question changes, this function will run
    '$root.language_option': function (newQuestion, oldQuestion) {
      // console.log("change" + this.$root.language_option);
      this.language = this.$root.language_option;
      setTimeout(() => {
        this.languages[this.language].register.intro = this.$parent.intro;
        this.languages[this.language].login.intro = this.$parent.intro;
      }, 500);
    },
    "user_name": function (newQuestion, oldQuestion) {
      if (this.user_name != '')
        document.getElementById('welcome_name').innerHTML = 'Hola ' + this.user_name;
      else
        document.getElementById('welcome_name').innerHTML = '';
    },
    "dObra.price.$numberDecimal": function (newQuestion, oldQuestion) {
      if (this.dObra.price.$numberDecimal > 100001 || this.dObra.price.$numberDecimal < 1)
        this.errors.obra_form_price = true;
      else
        this.errors.obra_form_price = false;
    },
  },
  mounted() {
    // console.log(this.$root.baseUrl_api);
    // https://sandbox.api.salonacme.com/opencall/

    if (this.$root.baseUrl_api.includes("sandbox.api.salonacme.com") || this.$root.baseUrl_api.includes("192.168.")) { /// production
      this.baseUrl_public = '';
    } else { /// test
      this.baseUrl_public = '/salon-acme-manager/';
    }

    this.convocatoriaIsOpen = false;
    this.theMounted();

    /*
    (async () => {

      await fetch(this.$root.baseUrl_api+'get_control', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({})
      }).then(async response => {
        response = await response.json();
        if (response.status_opencall) {
          this.convocatoriaIsOpen = true;
          this.theMounted();
        } else {
          this.convocatoriaIsOpen = false;
        }

      })
        .catch(error => alert(error));

    })();
    */

    this.convocatoriaIsOpen = true;
    this.theMounted();

    this.$parent.getOpencall(this.language);
    setTimeout(() => {
      this.languages[this.language].register.intro = this.$parent.intro;
      this.languages[this.language].login.intro = this.$parent.intro;
    }, 500);
  },
}
