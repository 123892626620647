import Vue from 'vue'
import App from './App.vue'
import router from './router'

import VueSession from 'vue-session'
Vue.use(VueSession);

import VeeValidate, { Validator } from "vee-validate"
import es from 'vee-validate/dist/locale/es'
// Vue.use(VeeValidate);
Vue.use(VeeValidate, {
  useConstraintAttrs: true
});
Validator.localize("es", es);

import logo from '@/components/icons/IconLogo.vue';

//Currency
import money from 'v-money'
Vue.use(money, {precision: 4});

//import vue-jquery from 'vue-jquery'
//Vue.use(vue-jquery)

import './assets/static';
//import './assets/scss/main.scss';

Vue.mixin({
  data: function() {
    return {
      baseUrl_api: (process.env.NODE_ENV === 'production')
      ? "https://sandbox.api.salonacme.com/opencall/"
      // : "https://sandbox.api.salonacme.com/opencall/"
      : "http://localhost:4003/opencall/"
    }
  }
});

Vue.config.productionTip = false

var vueData = new Vue({
  router,
  render: function (h) { return h(App) },
  data: {
    language_option : 'es'
  },
}).$mount('#app')
